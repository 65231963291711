import React, { ReactElement } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TriangleSeparator from '../components/TriangleSeparator'

import Error404 from '../icons/error-404.svg'
import Moth from '../icons/moth.svg'

const NotFoundPage = (): ReactElement => (
  <Layout>
    <SEO title="404: Page Not Found" />

    <TriangleSeparator mono={true} />

    <section className="container mx-auto flex flex-col md:items-center md:flex-row">
      <div className="inline-block self-center w-40 h-40">
        <Error404 />
      </div>
      <div className="md:ml-10 flex flex-col justify-center">
        <h2 className="header2 self-center md:self-start">Page Not Found</h2>
        <p className="mt-0">
          Perhaps the menu on the top right can help you out?
        </p>
      </div>
    </section>
    <section className="container mx-auto mt-5 mb-5">
      <hr />
      <h3 className="header5 mt-5">Did you know?</h3>
      <p className="text-lg">
        Grace Hopper and her team coined the term &ldquo;bug&rdquo; when they
        discovered a large moth in the circuitry of the Harvard Mark II after it
        malfunctioned.
      </p>
      <blockquote>
        <p>
          From then on, when anything went wrong with a computer, we said it had
          bugs in it.
        </p>
        <cite>
          &mdash; Rear Admiral Grace Hopper&nbsp;
          <a
            href="https://en.wikiquote.org/wiki/Grace_Hopper"
            target="_new"
            className="not-italic"
          >
            (Wikiquote)
          </a>
        </cite>
      </blockquote>
    </section>
    <div className="mx-auto w-24 h-24">
      <Moth />
    </div>
  </Layout>
)

export default NotFoundPage
